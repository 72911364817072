$inner-width: 1440px;
$inner-padding: 0 10px;

$color-dark-purple: #07001A;
$color-dark: #000000;

$color-light-purple: #E4CB77;
$color-light-purple-70: #FFFFFF;
$color-light-purple-30: #FFFFFF;

$color-light-blue: #31322C;
$color-light-blue-50: rgba(0, 128, 255, 0.5);

$color-light-red: #FF0000;
$color-purple-dark: #474A4C;

$color-white: #FFFFFF;
$color-black: #000000;
$color-purple-dark-font-color: #31322C;

$color-gold: #E4CB77;
$color-chocolate: #1A1A1A;
$input-bg-color: #FFFFFF;
$btn-basic-color: #FFFFFF; // only label
$btn-stroked-color: #FFFFFF; // only border
$btn-flat-color: #FFFFFF; // full filled
