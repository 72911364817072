@import "../variables";

ngx-intl-tel-input {

  .iti {
    position: relative;
    display: inline-block;
    width: 100%;
  }

  #country-search-box {
    height: 40px;
    flex-shrink: 0;
    color: $color-black;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 240% */
    padding: 5px;
    width: 100%;
    border-radius: 5px;

    &::-ms-input-placeholder { /* Edge 12-18 */
      color: $color-black !important;
      opacity: 0.3;
    }

    &::placeholder {
      color: $color-black !important;
      opacity: 0.3;
    }

  }
  .iti__country {
    font-size: 16px;
    font-style: normal;
  }
  .iti__country-list {
    width: 100%;
    white-space: normal;
  }
  .iti__flag-container.open.show {
    right: 2px;
  }
  .selected-dial-code {
    font-size: 16px;
    color: $color-white;
  }
  .iti__arrow {
    margin-left: 6px;
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 4px solid $color-white;
  }
}
