@use '@angular/material' as mat;

@import '../../../node_modules/ngx-bootstrap/datepicker/bs-datepicker.css';
@import '../../../node_modules/@angular/material/prebuilt-themes/indigo-pink.css';
@import '../../../node_modules/bootstrap/scss/bootstrap.scss';
@import '../../../node_modules/intl-tel-input/build/css/intlTelInput.css';
@import '../../../node_modules/@splidejs/splide/dist/css/splide.min.css';
@import "variables";

.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-focus-icon-color: #1A1A1A;
  --mdc-checkbox-selected-hover-icon-color: #C3C4C7;
  --mdc-checkbox-selected-icon-color: #C3C4C7;
  --mdc-checkbox-selected-pressed-icon-color: #C3C4C7;
  --mdc-checkbox-unselected-focus-icon-color: #C3C4C7;
  --mdc-checkbox-unselected-hover-icon-color: #C3C4C7;
  --mdc-checkbox-disabled-selected-icon-color: #1A1A1A;
  --mdc-checkbox-disabled-unselected-icon-color: #1A1A1A;
  --mdc-checkbox-unselected-icon-color: #C3C4C7;;
  --mdc-checkbox-unselected-pressed-icon-color: #1A1A1A;
  --mdc-checkbox-selected-checkmark-color: #1A1A1A;
}

/* fonts */
@font-face {
  font-family: 'AvenirNext';
  src: url('assets/fonts/AvenirNext/AvenirNextCyr-Medium.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNext';
  src: url('assets/fonts/AvenirNext/AvenirNextCyr-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNext';
  src: url('assets/fonts/AvenirNext/AvenirNextCyr-LIghtBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNext';
  src: url('assets/fonts/AvenirNext/AvenirNextCyr-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNext';
  src: url('assets/fonts/AvenirNext/AvenirNextCyr-HeavyItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  background-color: #000000;
  font-size: 14px;
  font-family: 'AvenirNext', 'Manrope', sans-serif;
  color: #fff;
  scroll-behavior: smooth;
}

.layout-with-side-menu {

  display: flex;
  flex-direction: row;
  //background-color: red;
  align-items: stretch;
  align-content: stretch;
  height: 100%;
  gap: 16px;

  .page-container {
    display: flex;
    flex-direction: column;
  }

  .layout-left-side-menu {
    display: flex;
    align-items: start;
    width: 280px;
    background-color: #1A1A1A;
    border-radius: 8px;
    min-height: 100%;
    align-content: stretch;
    //padding: 10px;
    position: relative;

    .side-menu {
      display: block;
      //background-color: red;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      background-color: #1A1A1A;
      position: sticky;
      top: 80px;
      width: 100%;
      //padding: 16px;
    }

    @media (max-width: 992px) {
      display: none;
    }

  }

  .layout-main-content {
    display: block;
    flex: 1;
    //background-color: blue;
    overflow: hidden;
    min-height: 100%;
  }
}

.main-container {
  max-width: 1440px;
  background-color: $color-dark;
  margin: 0 auto;
  //padding-top: 60px;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 14px;
  font-family: 'AvenirNext', 'Manrope', sans-serif;
  font-weight: 900;
}

h1  {
  font-size: 24px;
  font-weight: 700;
  margin: 30px 0;
  color: #C3C4C7;
}

p {
  font-family: 'AvenirNext', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #C3C4C7;
  line-height: 28px;
}

.header-section {
  position: sticky;
  z-index: 1006;
  right: 0;
  left: 0;
  top: 0;
  background-color: $color-dark;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.body-section {

  position: relative;
  max-width: $inner-width;
  margin: 0 auto 16px auto;
  padding: $inner-padding;
  width: 100%;
}

.csn-btn {

  padding: 8px 32px;
  cursor: pointer;
  color: $color-white;
  font-family: 'AvenirNext', 'Manrope', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  column-gap: 8px;
  height: 46px;
  border-radius: 4px;
  //box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

  &.active {
    border-radius: 4px;
    background: $color-purple-dark-font-color !important;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  }

  &:disabled {
    background-color: $color-purple-dark-font-color !important;
    color: $color-white !important;
  }

  &:hover {
    opacity: 0.8;
  }

  &:hover:enabled {
    opacity: 0.8;
  }

  &.purple {
    background-color: $color-light-purple;
    color: $color-black;
    &:hover:enabled {
      background-color: $color-light-purple-70 !important;;
    }
  }

  &.light-purple {
    background-color: $color-light-purple-70;
    color: $color-black;
  }

  &.light-purple-outline {
    border: 1px solid $color-light-purple-70;
    color: $color-white;
  }

  &.dark-purple {
    background-color: $color-chocolate;
    color: $color-white;
  }

  &.light-blue {
    background-color: $color-light-blue;
    color: $color-white;
  }

  &.contrast {
    background-color: $color-purple-dark-font-color;
    color: $color-white;
  }

  &.dark {
    background-color: $color-purple-dark;
    color: $color-purple-dark-font-color;
  }

  &.size-sm {
    height: 27px;
    font-size: 12px;
  }

  &.w-100 {
    width: 100%;
  }

  &.flat {
    background-color: $color-light-purple-30;
    color: $color-white;
    box-shadow: none;
    padding: 8px;
    column-gap: 4px;
    height: 43px;
  }

  @media (max-width: 576px) {
    padding: 8px 15px;
  }

}

.csn-flex-items-center {
  display: flex;
  align-items: center;
  column-gap: 10px;

  &.gap-5 {
    column-gap: 5px;
  }
}

/* custom icons */
.csn-icon {

  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 30px;
  height: 30px;

  &.size-30 {
    width: 30px;
    height: 30px;
    background-size: 30px 30px;
  }

  &.size-15 {
    width: 15px;
    height: 15px;
    background-size: 15px 15px;
  }

  &.size-20 {
    width: 20px;
    height: 20px;
    background-size: 20px 20px;
  }

  &.size-50 {
    width: 50px;
    height: 50px;
    background-size: 50px 50px;
  }

  &.size-100 {
    width: 100px;
    height: 100px;
    background-size: 100px 100px;
  }

  &.horseshoe-icon {
    background-image: url('assets/img/horseshoe.svg');
  }

  &.leaf-icon {
    background-image: url('assets/img/leaf.svg');
  }

  &.money-icon {
    background-image: url('assets/img/money.svg');
  }

  &.gold-account-icon {
    background-image: url('../../../src/skins/lions/assets/img/account-gold.svg');
  }

  &.silver-account-icon {
    background-image: url('../../../src/skins/lions/assets/img/account-silver.svg');
  }

  &.bronze-account-icon {
    background-image: url('../../../src/skins/lions/assets/img/account-bronze.svg');
  }

  &.chip-icon {
    background-image: url('assets/img/chip.png');
    @media only screen and (max-width: 768px) {
      background-image: url('assets/img/mobile/chip-mobile.png');
    }
  }

  &.playing-cards-icon {
    background-image: url('assets/img/playing-cards.svg');
  }

  &.fishki-icon {
    background-image: url('assets/img/fishki.svg');
  }

  &.coins-icon {
    background-image: url('assets/img/coins.svg');
  }

  &.diamond-icon {
    background-image: url('assets/img/diamond.svg');
  }

  &.noname-icon {
    background-image: url('assets/img/noname.svg');
  }

  &.lion-top-games-icon {
    background-image: url('assets/img/home/lion-top-games.png');
  }

  &.lion-providers-icon {
    background-image: url('assets/img/home/lion-providers.png');
  }

  &.lion-new-games-icon {
    background-image: url('assets/img/home/lion-new-games.png');
  }

  &.lion-all-games-icon {
    background-image: url('assets/img/home/lion-all-games.png');
  }

  &.bonus-icon {
    background-image: url('assets/img/home/lion-bonus-icon.png');
  }

  &.cashback-icon {
    background-image: url('assets/img/home/lion-cashback-icon.png');
  }

  &.award-icon {
    background-image: url('assets/img/home/lion-award-icon.png');
  }

  &.shield-icon {
    background-image: url('assets/img/home/lion-shield-icon.png');
  }

  &.finger-icon {
    background-image: url('assets/img/home/lion-finger-icon.png');
  }

  &.crown-icon {
    background-image: url('assets/img/home/lion-crown-icon.png');
  }

  &.expand-more-icon {
    background-image: url('assets/img/expand-more-icon.png');
  }

  &.deposit-icon {
    background-image: url('assets/img/home/lion-deposit-icon.png');
  }

  &.withdraw-icon {
    background-image: url('assets/img/home/lion-withdraw-icon.png');
  }

  &.star-icon {
    background-image: url('assets/img/home/lion-star-icon.png');
  }

  &.history-icon {
    background-image: url('assets/img/home/lion-history-icon.png');
  }

  &.personal-icon {
    background-image: url('assets/img/home/lion-personal-icon.png');
  }

  &.exit-icon {
    background-image: url('assets/img/home/lion-exit-icon.png');
  }

  &.sead-clock-icon {
    background-image: url('assets/img/home/lion-sead-clock.png');
  }

  &.email-icon {
    background-image: url('assets/img/home/lion-email-icon.png');
  }

  &.phone-icon {
    background-image: url('assets/img/home/lion-phone-icon.png');
  }
}

.visa-icon {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 144px;
  height: 45px;
  background-image: url('assets/img/visa.svg');
}

/* html form controls */

.csn-form-control-inline {
  display: flex;
  align-items: center;
  //gap: 10px;
}

.csn-form-group {

  margin-bottom: 10px;
  text-align: left;

  &.text-align-center {
    text-align: center;
  }

  &.inline-group {
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .csn-label {
    color: #C3C4C7;
    font-family: 'AvenirNext', serif;
    font-size: 16px;
    font-style: normal;
    line-height: 24px; /* 240% */
    margin-bottom: 5px !important;
    padding-left: 5px;
  }

  .form-input-select-option {
    background: $color-purple-dark;
  }

  .csn-form-control {

    font-family: 'AvenirNext', serif;
    border-radius: 5px;
    border: 1px solid #000;
    background: $color-light-purple-30;
    height: 40px;
    flex-shrink: 0;
    color: #000000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 240% */
    padding: 12px 9px;
    width: 100%;

    &::-ms-input-placeholder { /* Edge 12-18 */
      color: #C3C4C7 !important;
    }

    &::placeholder {
      color: #C3C4C7 !important;
    }

    &.size-sm {
      height: 27px;
    }

    &.size-lg {
      height: 45px;
    }

    &.grey {
      border-radius: 5px;
      background-color: rgba(0, 0, 0, 0.25);
    }

    &.green {
      border-radius: 5px;
      background-color: $color-light-purple-30;
    }

    &.w-auto {
      width: auto;
    }

    &.w-100 {
      width: 100%;
    }

    &.flex-1 {
      flex: 1;
    }

    &.is-invalid {
      &.ng-touched {
        border: 2px solid #FF0000FF;
      }
    }

  }

  .csn-form-checkbox {

    display: flex;
    align-items: center;
    column-gap: 10px;

    .csn-form-control {
      height: 25px;
      width: 25px;
    }

  }

  .csn-datepicker-toggle {
    .mat-mdc-icon-button {
      height: 30px;
      width: 30px;
      padding: 0;
      margin: 0;
      left: 7px;
      color: $color-light-purple;
    }
  }

  select {
    &.csn-form-control {
      padding: 0;
    }
  }

  input {
    &::placeholder {
      color: #C3C4C7 !important;
    }
  }

  button {
    border-width: 0 !important;
  }

}

.is-invalid {
  .csn-form-control {
    border: 2px solid #FF0000FF !important;
  }
}

form {
  margin: 0 !important;
}

.flex-1 {
  flex: 1 !important;
}

.csn-alert {
  padding: 50px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error {
  color: #F00;
  font-family: 'AvenirNext', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 333.333% */
}

/* odometer on home page */
.odometer.odometer-auto-theme, .odometer.odometer-theme-default {
  color: #C3C4C7;
  font-family: 'AvenirNext' !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 0.8px;
}

.cdk-overlay-container {
  z-index: 1020 !important;
}

//.cdk-overlay-pane:not(.mat-mdc-autocomplete-panel-above) .mdc-menu-surface.mat-mdc-autocomplete-panel {
//  background-color: $color-light-blue;
//}

/* dialogs temporary please remove after testing */
.mat-mdc-dialog-container .mdc-dialog__surface {
  color: #000;
}

.mdc-card {
  padding: 10px;
}

.cursor-pointer {
  cursor: pointer;
}

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$custom-typography: mat.define-legacy-typography-config(
  $font-family: 'AvenirNext',
  $headline: mat.define-typography-level(24px, 28px, 700),
  $title: mat.define-typography-level(1.5rem, 2.3rem, 500),
  $subheading-1: mat.define-typography-level(20px, 24px, 900),
  $subheading-2: mat.define-typography-level(18px, 22x, 900),
  $body-1: mat.define-typography-level(16px, 18px, 400));

@include mat.legacy-typography-hierarchy($custom-typography);
@include mat.legacy-checkbox-typography($custom-typography);
@include mat.all-legacy-component-typographies($custom-typography);
@include mat.legacy-core();

@import "overrides/intl-tel-input";

/*** AFFILIATE ***/
@import "../../affiliate";

.mat-mdc-dialog-container {
  .mdc-dialog__surface {
    border-radius: 8px !important;
    background-color: $color-chocolate !important;
    border: 1px solid $color-chocolate !important;
  }
}

.mat-mdc-checkbox .mdc-form-field {
  color: #C3C4C7 !important;
  font-size: 14px !important;
}

.mat-mdc-checkbox label {
  margin-bottom: 0 !important;
}

.cdk-overlay-pane:not(.mat-mdc-autocomplete-panel-above) .mdc-menu-surface.mat-mdc-autocomplete-panel {
  background-color: $color-chocolate !important;
}

/* scroll customization */
/* width */
::-webkit-scrollbar {
  width: 15px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #000000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2E2E2E;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $color-light-purple;
}

.dark-scroll::-webkit-scrollbar-thumb {
  background: #000000;
}

.dark-scroll::-webkit-scrollbar {
  height: 10px;
}
