.affiliate-confirmation-dialog {

  .btn-orange {
    background-color: #FFFFFF;
    border: 1px solid #FF5A2D;
    color: #000000;

    &.border-left {
      background-color: #FF5A2D;
      color: #FFFFFF;
    }
  }

  .mat-mdc-card-header {
    display: flex;
    align-items: start;
    justify-content: space-between;
    padding: 16px 16px 0;
  }

  .mat-mdc-card-title {
    font-size: 1.1rem;

    .logo-text {
      font-size: 1.5rem;
      font-weight: bold;
    }
  }

}
